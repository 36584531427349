@import "vars.scss";


.template-picker {

    h2 {
        margin: 0 0 30px 0;
        font-size: 16px;
        color: $TextColor;
    }

    .preview {
        margin: 30px 0 60px 0;
        overflow: auto;
    }

    .button-container {
        position: absolute;
        bottom: 30px;
        left: 27px;
        right: 27px;
        background: $ContentBackground;
        padding-top: 10px;
    }

    .btn.insert {
        float: right;
    }
}

.template-picker-modal .modal {
    width: 90%;
    max-width: 600px;
    position: relative;

    .modal-content {
        height: 100%;
    }
}