@import "vars.scss";


.schedule-alert-modal {

    .modal {
        width: 90%;
        max-width: 312px;
        padding: 24px 26px 30px 26px;
    }

    .modal-content {
        padding-right: 0;
    }

    h4 {
        margin: 0 0 8px 2px;
        padding: 0;
        font-weight: 600;
        font-size: 16px;

        &.time-label {
            margin-top: 30px;
        }
    }


    .error-msg {
        @include errorMsg;
        margin: 16px 0;
    }

    .btn-container {
        overflow: hidden;

        :last-child {
            float: right;
        }
    }
}


.schedule-alert-modal-ready {
    text-align: center;

    h4 {
        font-size: 20px;
        font-weight: 700;
        margin: 20px;
    }

    p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
    }

    .btn-container {
        margin-top: 35px;
        overflow: hidden;

        :last-child {
            float: right;
            margin-left: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}