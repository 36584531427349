@import "vars.scss";


.voice-recorder {

    .box-body {
        text-align: center;
        padding: 28px 14px 28px 14px;
    }

    .record-btn {
        cursor: pointer;

        i {
            color: var(--color-text-lighter-opacity-70);
            font-size: 26px;
            display: inline-block;
            width: 65px;
            height: 65px;
            text-align: center;
            line-height: 65px;
            border: 2px solid var(--color-text-lighter-opacity-70);
            border-radius: 33px;
            font-weight: 900;
        }

        &.recording i {
            border-color: $Warning;
            font-size: 20px;
            line-height: 62px;
        }
    }

    .player {
        margin-top: 17px;
    }

    .recording-time {
        display: block;
        margin-top: 8px;
        font-size: 16px;
    }

    &.has-error {
        label {
            color: $Warning;
        }

        .error-msg {
            text-align: center;
            padding-bottom: 15px;
        }
    }
}