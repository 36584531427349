@import "vars.scss";



.page-content {
    .group-picker {
        left: $SidebarCollapsedWidth;

        @media ($IsMobile) {
            left: 0px;
        }
    }

    @media ($IsDesktop) {
        &.expanded-side-bar {
            .group-picker {
                left: $SidebarWidth;
            }
        }
    }
}

.group-picker {
    background: $ContentBackground;
    position: fixed;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: top .5s ease;
    overflow: hidden;

    &.open {
        top: $TopBarHeight;
    }

    .top-area {
        display: flex;
        padding: 18px 16px 18px 0;
        box-shadow: $BoxShadow;
    }

    .back {
        display: inline-block;
        padding: 0 15px 0 24px;
        color: $TextColor;
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
        opacity: .75;
        cursor: pointer;

        i {
            margin-right: 5px;
        }

        &:hover {
            opacity: 1;
        }
    }

    .search-box {
        width: 360px;
        margin-right: 24px;
    }

    .scroll-container {
        position: absolute;
        top: 76px;
        left: 0;
        right: 0;
        bottom: 52px;
        overflow-y: auto;
    }

    .item {
        padding: 13px 46px 13px 19px;
        border-bottom: 1px solid $LightBorder;
        height: 47px;
        position: relative;
        cursor: pointer;

        i {
            margin-right: 14px;
        }

        .gui-checkbox {
            span {
                max-width: calc(100% - 50px);
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: top;
            }
        }

        .count {
            position: absolute;
            right: 30px;
            top: 13px;
        }


        &.all-users {
            font-style: italic;
        }

        &.selected {
            background: var(--color-selected-item-background);
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        line-height: 52px;
        border-top: 1px solid $LightBorder;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: var(--color-text-lighter);
        background: $ContentBackground;

        span {
            opacity: .5;
        }
    }

    .loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-ligther-blackout);
        padding-top: 20px;
        text-align: center;
    }
}