@import "vars.scss";



.expand-panel {
    background: $ContentBackground;
    border-bottom: 1px solid $LightBorder;

    h4 {
        margin: 0;
        padding: 15px;
        font-weight: 600;
        font-size: 16px;
        color: $TextColor;
        cursor: pointer;

        i {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            font-weight: 400;
            font-size: 18px;
            opacity: .4;
        }

        .edit-icon {
            display: none;
        }

        &.invalid {
            color: $Warning;
        }
    }

    &.expanded {
        h4 {
            i {
                color: $PrimaryColor;
                opacity: 1;
            }
        }
    }

    &.complete {
        h4 {
            i {
                color: $PrimaryColor;
                opacity: 1;
                font-weight: 600;
            }

            .edit-icon {
                display: inline-block;
                float: right;
                color: var(--color-text-lighter);
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .resizer-content {
        padding: 15px 16px 30px 16px;
    }
}