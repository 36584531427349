@import "vars.scss";

/* Title */
.page-layout-header {
    position: relative;
    background: $ContentBackground;
    box-shadow: $HeaderShadow;
}
.page-layout-header h3 {
    margin: 0;
    padding: 0 4px 0 24px;
    font-size: $FontSizeLarge;
    font-weight: 400;
    line-height: 63px;
}
/* Top Buttons */
.page-layout-top-buttons {
    position: absolute;
    top: 0;
    right: 0;
}
.page-layout-top-buttons button {
    margin: 16px 18px 0 0;
    font-size: $FontSizeSmall;
}
/* Tabs */
.page-layout-tab-bar {
    clear: both;
    padding: 0 25px;
}
.page-layout-tabs span {
    display: inline-block;
    padding: 3px 20px 0 20px;
    font-size: $FontSizeSmall;
    line-height: 48px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
}
.page-layout-tabs span.active {
    font-weight: 600;
    letter-spacing: -.3px;
    border-color: $PrimaryColor;
}
/* Content */
.page-layout-content-header {
    padding: 24px 24px 12px 24px;

    .search-box {
        width: 360px;
        margin-right: 24px;
    }
    .tools {
        float: right;
    }
}


@media($IsMobile) {

    /* Mobile Header */
    .page-layout-header {
        box-shadow: none;
    }

    .page-layout-header h3 {
        line-height: 68px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    /* Mobile Search */
    .page-layout-header i.fa-search {
        margin-right: 10px;
        padding: 12px;
        font-weight: 900;
        color: var(--color-text-lighter);
        opacity: .7;
    }

    .mobile-search h3 {
        color: transparent;
    }

    .mobile-search .page-layout-top-buttons {
        display: none;
    }

    .mobile-search .page-layout-mobile-search-bar {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding-left: 16px;
        line-height: 68px;
    }

    .mobile-search .search-box {
        padding-left: 0;
    }

    .mobile-search input {
        width: 150px;
    }

    .mobile-search i.fa-search {
        margin-right: 0;
    }

    .mobile-search .cancel {
        display: block;
        float: right;
        padding: 0 30px 0 10px;
        color: $PrimaryColor;
        font-weight: 600;
        cursor: pointer;
    }

    /* Tab bar */
    .page-layout-tab-bar {
        padding: 0;
        line-height: 64px;
        text-align: center;
        border-bottom: 1px solid $LightBorder;
    }

    .page-layout-tabs {
        display: inline-block;
        padding: 3px;
        line-height: 1em;
        border-radius: 4px;
        background: var(--color-gray-medium-background);
    }

    .page-layout-tabs > span {
        padding: 4px 10px;
        color: var(--color-text-lighter);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        border-bottom: 0;
    }

    .page-layout-tabs > span.active {
        color: var(--color-text-lighter-opacity-70);
        background-color: $ContentBackground;
        border-radius: 4px;
    }

    /* Mobile Body */
    .page-layout-body > .card {
        margin: 0;
        box-shadow: none;
        border-radius: 0;
    }
}