@import "vars.scss";


.draggable-file-upload {
    border: 2px dashed $InputBorderColor;
    border-radius: 5px;
    margin: 15px 40px;
    padding: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;

    i {
        font-size: 22px;
        display: inline-block;
        margin: 10px 0 15px 0;
    }

    p {
        margin: 0;
    }

    a {
        color: $PrimaryColor;
        cursor: pointer;
    }

    &.file-hover {
        border-color: $PrimaryColor;
    }
}

.file-upload-in-progress {
    width: auto;
    margin: 15px 40px;
    border: 2px dashed $InputBorderColor;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    padding: 7px;
    line-height: 21px;
    color: var(--color-text-lighter);

    .fa-file {
        margin-right: 10px;
    }

    .file-progress-bar {
        width: 226px;
        height: 4px;
        border-radius: 2px;
        background-color: $PrimaryColor;
        display: inline-block;
        margin-right: 4px;

        .file-progress {
            height: 4px;
            border-radius: 2px;
            background-color: $PrimaryColor;
        }
    }

    .fa-times {
        font-size: 18px;
        margin-right: 4px;
        vertical-align: middle;
        color: $TextColor;
        cursor: pointer;
    }
}