@import "vars.scss";


.modal-background {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: $ModalZ;
    background-color: var(--color-blackout);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal {
        margin: auto;
        background: $ContentBackground;
        border-radius: 12px;
        padding: 28px 0 28px 28px;
        max-width: 85vw;
        max-height: 85vh;
        box-shadow: 5px 7px 28px 5px rgba(0, 0, 0, 0.0503442);

        @media ($IsMobile) {
            max-width: calc(100vw - 16px);
        }
    }

    .modal-content {
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 28px;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: $ContentBackground;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $InputBorderColor;
            border-radius: 6px;
            border: 1px solid $InputBackgroundColor;
            border: 1px solid $InputBackgroundColor;
        }
    }
}

.fade-container {
    opacity: 0;
    transition: opacity $AnimationLength ease;

    &.fade-in {
        opacity: 1;
    }
}

.blackout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $BlackoutZ;
    background: var(--color-blackout);
}

/* Plain Modal */
.plain-modal .modal {
    position: relative;
}

.plain-modal .modal-content {
    max-height: 100%;
}

.plain-modal,
.plain-modal input,
.plain-modal select,
.plain-modal textarea {
    color: $TextColor;
}

.plain-modal h3 {
    margin: 0 0 36px 0;
    font-size: 20px;
    font-weight: 700;
}

.plain-modal .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    color: var(--mb-color-styles-button-black);
    font-weight: 400;
    cursor: pointer;
}
.plain-modal.no-close .close {
    display: none;
}

.plain-modal p {
    margin: 0 0 24px 0;
    max-width: 90%;
}
.plain-modal .buttons {
    margin-top: 36px;
    text-align: right;
}
.plain-modal button {
    margin-left: 12px;
}

.plain-modal.loading .modal-content {
    opacity: .35;
    pointer-events: none;
}
