@import "vars.scss";


.add-person .expand-panel:first-child {
    border-top-left-radius: $LargeRoundedCorners;
    border-top-right-radius: $LargeRoundedCorners;
}

.add-person .expand-panel:last-child {
    border-bottom-left-radius: $LargeRoundedCorners;
    border-bottom-right-radius: $LargeRoundedCorners;
}


