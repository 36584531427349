@import "../vars.scss";


.gui-checkbox {
    white-space: nowrap;
    cursor: pointer;

    input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    i.box {
        display: inline-block;
        font-size: .75em;
        border: .2em solid $OutlineButtonBorder;
        border-radius: .2em;
        width: 1.6em;
        height: 1.6em;
        line-height: 1.2em;
        background: transparent;
        color: transparent;
        transition: background-color $AnimationLength, border-color $AnimationLength;
        text-align: center;
        vertical-align: middle;
    }

    input:checked + i.box {
        border-color: $PrimaryColor;
        background-color: $PrimaryColor;
        color: $ContentBackground;
    }

    i.box + span {
        margin-left: .6em;
    }

    &.disabled {
        opacity: .35;
        pointer-events: none;
    }

    &.grid-select-all i::before {
        font-size: .7em;
    }
    /*.gui-radio .error-msg {
        display: none;
    }

    .gui-radio.has-error .error-msg {
        display: block;
        color: var(--color-error);
        font-size: .8em;
        margin: .1em 0 0 .1em;
    }*/
}
