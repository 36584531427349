@import "vars.scss";


.edit-person-modal .checkbox-row {
    margin: 20px 0 0 0;
}

.edit-person-modal .gui-select {
    margin-bottom: 20px;
}

.edit-person-modal .city,
.edit-person-modal .state,
.edit-person-modal .zip {
    display: inline-block;
    width: 30%;
    margin-right: 5%;
}

.edit-person-modal .zip {
    margin-right: 0;
}
