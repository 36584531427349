@import "vars.scss";

$ItemHeight: 45px;


.side-bar {
    position: fixed;
    top: $TopBarHeight;
    bottom: 0px;
    background: $SideBarBackground;
    z-index: $SidebarZ;
    transition: width $AnimationLength ease, transform $AnimationLength ease;
    width: $SidebarWidth;
    overflow: hidden;

    .side-bar-item-container {
        width: $SidebarWidth;
    }

    .side-bar-item {
        @include MenuItem;
        font-weight: 400;
        font-size: $FontSizeNormal;
        line-height: $ItemHeight;
        cursor: pointer;
        position: relative;

        .icon {
            margin: 0 16px 0 14px;
            display: inline-block;
            width: 16px;
        }

        i.fa-chevron-down {
            display: inline-block;
            position: absolute;
            right: 0;
            width: 44px;
            font-size: 11px;
            line-height: $ItemHeight;
            font-weight: 900;
            text-align: center;
            transition: transform $AnimationLength ease;
            cursor: pointer;

            &.expanded {
                transform: rotate(-180deg);
            }
        }

        &.selected {
            @include MenuItemSelected;
        }

        &:hover {
            @include MenuItemHover;
        }

        &.selected:hover {
            border-color: $SideBarHoverText;
        }
    }

    .side-bar-sub-items {
        .side-bar-item {
            @include MenuSubItem;

            &:hover {
                @include MenuSubItemHover;
            }

            &.selected {
                @include MenuSubItemSelected;

                &:hover {
                    background: var(--color-sidebar-sub-item-hover-background);
                }
            }
        }
    }


    @media ($IsMobile) {
        .collapse-button {
            display: none;
        }

        &.collapsed {
            transform: translateX(-100%);
        }
    }

    @media ($IsDesktop) {
        &.collapsed {
            width: $SidebarCollapsedWidth;
            overflow: visible;

            .side-bar-item-container {
                width: auto;
            }

            span {
                display: none;
            }

            > .side-bar-item-container > .side-bar-item {
                text-align: center;
            }

            .side-bar-item i.fa-chevron-down {
                display: none;
            }

            .side-bar-sub-items {
                height: 0px;
            }

            .side-bar-item-container:hover .resizer-content {
                display: block;
                position: absolute;
                left: $SidebarCollapsedWidth;
                height: auto;
                margin-top: -$ItemHeight;
                width: 234px;

                i {
                    display: none;
                }

                span {
                    display: inline;
                    margin-left: 20px;
                }

                > :first-child {
                    display: block;

                    .side-bar-item {
                        @include MenuItemHover;
                        font-weight: 600;
                    }
                }
            }
        }

        .collapse-button {
            span {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
                vertical-align: bottom;
            }

            i {
                transition: transform $AnimationLength ease;
            }
        }

        &.collapsed .collapse-button i {
            transform: rotate(-180deg);
        }
    }
}