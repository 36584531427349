@import "vars.scss";


.help-page {

    .container-card {
        background: var(--color-help-background);
        padding-bottom: 50px;
        margin: auto;
    }

    img {
        display: block;
        height: 35%;
        width: 35%;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-left: auto;
        margin-right: auto;

        @media ($IsMobile) {
            height: 75%;
            width: 75%;
        }
    }

    .address-text {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .team-title {
        font-size: 25px;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 8px;
    }

    .support-text {
        font-weight: 600;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
    }

    .support-link {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 15px;
        opacity: 0.6;

        a {
            text-decoration: none;
            opacity: 0.6;
            color: black;
        }
    }

    .update-section {
        text-align: center;

        span {
            display: block;
            padding: 50px 0 10px 0;
            font-size: 14px;
        }
    }
}
