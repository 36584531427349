@import "../vars.scss";


.gui-textarea {
    position: relative;
    border: 1px solid $InputBorderColor;
    border-radius: 4px;

    textarea {
        @include guiInput;
        font-family: var(--font-family);
        resize: none;
        border: 0;
    }

    > label {
        @include guiLabel;
    }

    &.in-focus {
        @include guiInputFocused;

        > label {
            @include guiLabelFocused;
        }
    }

    &.has-value, &.in-focus {
        > label {
            @include guiLabelWithValue;
        }
    }

    &.has-error {
        > label {
            color: $Error;
        }

        input {
            border-color: $Error;
        }

        .error-msg {
            display: block;
            color: $Error;
            font-size: .8em;
            margin: .1em 0 0 .1em;
        }
    }
}
    
