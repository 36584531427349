@import "vars.scss";


.search-box {
    display: inline-block;
    font-size: 16px;
    color: var(--color-search-icon-text);
    line-height: 38px;
    padding: 0 12px;
    border: 1px solid $InputBorderColor;
    border-radius: $SmallRoundedCorners;
    white-space: nowrap;

    i {
        margin-right: 8px;
        color: var(--color-text-lighter);
    }

    input {
        font-size: 16px;
        font-family: var(--font-family);
        color: $TextColor;
        background: transparent;
        border: 0;
        outline: none;
        width: calc(100% - 38px);

        &::placeholder {
            color: var(--color-search-icon-text);
        }
    }
}