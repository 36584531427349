@import "vars.scss";

.merge-group-modal {
    .modal {
        width: 560px;
    }

    .merge-type {
        margin-top: 32px;
    }

    .merge-type label {
        display: block;
        margin-bottom: 16px;
    }

    button {
        padding: 8px 16px;
    }
}