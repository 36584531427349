@import "vars.scss";



.edit-multiple-count {
    font-size: $FontSizeSmall;
    color: $TextSecondary;
    margin-right: 16px;
}

.edit-multiple-button {
    padding: 8px 16px;

    > i {
        transition: transform $AnimationLength ease;
    }

    &.active > i {
        transform: rotate(-180deg);
    }
}

.edit-multiple-popover {
    &.popover-anchor {
        display: inline-block;
    }
    /*.popover {
        top: 14px;
        right: auto;
        left: 0;
        width: 303px;
        padding: 36px 24px 24px 24px;
    }

    .gui-select {
        margin-bottom: 36px;
    }

    .buttons {
        text-align: right;
    }

    .btn-text {
        margin-right: 12px
    }*/
}