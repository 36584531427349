@import "../vars.scss";


.gui-calendar.react-calendar {
    border: 0px;
    color: $TextColor;

    * {
        font-family: var(--font-family);
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
        display: none;
    }

    .react-calendar__navigation button:enabled:focus,
    .react-calendar__navigation button:enabled:active,
    .react-calendar__navigation button:enabled:hover {
        background-color: transparent;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 6px
    }

    .react-calendar__navigation__label {
        font-size: 16px;
        font-weight: 600;
    }

    .react-calendar__month-view__weekdays__weekday {
        abbr {
            text-transform: none;
            text-decoration: none;
        }
    }

    .react-calendar__tile {
        font-size: 14px;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: $TextColor;
        opacity: .4;
    }

    .react-calendar__month-view__days__day--weekend {
        color: $TextColor;
    }

    .react-calendar__tile--now {
        background-color: $LightBorder;
        border-radius: 4px;

        &:enabled:hover {
            background-color: $LightBorder;
        }
    }

    .react-calendar__tile--active {
        background-color: $PrimaryColor;
        color: $TextColorInverted;
        border-radius: $SmallRoundedCorners;
        /*&::before {
            content: "";
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            margin: auto;
            background-color: $PrimaryColor;
            border-radius: 15px;
        }*/
    }
}