@import "vars.scss";

.grid {

    .row {
        border-bottom: 1px solid $LightBorder;
    }

    .row > span {
        position: relative;
        width: 100px; /* Starting Width */
        padding: 0 12px;
        line-height: 48px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    .row.header-row > span {
        line-height: 60px;
    }

    .pager {
        line-height: 50px;
        padding-left: 15px;
    }

    .header-row.row {
        font-size: $FontSizeTiny;
        font-weight: 600;
        border-bottom: 1px solid $GridHeaderBottomBorder;

        .name {
            color: $TextColor;
        }

        span.sortable {
            cursor: pointer;
        }

        span.sorted {
            font-weight: 600;
        }

        span.sorted::after {
            content: "\f176";
            font-family: "Font Awesome 6 Pro";
            position: absolute;
            font-size: $FontSizeSmall;
            color: $IconGray;
            font-weight: 400;
            margin: 0 0 0 8px;
        }

        span.sorted.desc::after {
            transform: rotate(-180deg);
            margin-top: -1px;
        }
    }
    /*.items-container {
        position: relative;
    }*/

    .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 5px;
        background: var(--color-whiteout);
        text-align: center;
        font-size: 40px;
        color: $PrimaryColor;
        @include fadeIn;

        &.no-items {
            padding: 0px;
            margin-top: -2px;
        }
    }

    .checkbox-header {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        border-radius: 2.5px;
        background: transparent;
        cursor: pointer;
    }

    .checkbox-header.minus {
        color: var(--inverted-text-color);
        font-weight: 600;
        background: $PrimaryColor;
    }
}


@media($IsMobile) {
    /* Scroll container */
    .grid {
        position: absolute;
        top: 198px;
        left: 0;
        right: 0;
        bottom: 52px;
        overflow-y: auto;
        background: $ContentBackground;
    }

    /* Default Mobile Item Stying */
    .grid .item {
        display: flex;
        padding-left: 15px;
        height: 65px; /* Important for infinte-scrolling estimatation */
        line-height: 64px;
        border-bottom: 1px solid $LightBorder;
    }

    .grid .item > .info {
        flex: 1 1 auto;
        line-height: 1em;
        padding-left: 12px;
    }

    .grid .item h6 {
        margin: 16px 0 0 0;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .grid .item p {
        margin: 8px 0 0 0;
        font-size: 10px;
    }

    .grid .item > i.fa-chevron-right {
        padding: 0 16px 0 8px;
        line-height: 64px;
        color: var(--color-text-lighter);
        opacity: .7;
    }

    /* Mobile footer */
    .grid-mobile-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        line-height: 52px;
        border-top: 1px solid $LightBorder;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        color: var(--color-text-lighter);
        background: $ContentBackground;
    }
}