@import "vars.scss";

body.login-page {
    background: $ContentBackground;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-screen {
    width: 280px;
    max-width: 95%;
    margin: -50px auto 0 auto;
    color: $TextColor;

    .nav-button {
        padding-left: 8px;
        cursor: pointer;

        i {
            padding-right: 13px;
        }
    }

    .image {
        text-align: center;
    }

    h2 {
        padding-top: 30px;

        &.sign-in-title {
            padding-top: 0;
            margin: 10px 0 24px 0;
        }
    }

    .gui-textbox input {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
    }

    .password {
        margin-top: 24px;
    }

    .code {
        margin-top: 40px;
    }

    .text {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px
    }

    .help-text {
        opacity: 0.4;
        padding: 0 0 15px 2px;
        font-size: 12px;
    }

    .gui-btn {
        font-size: 16px;
        line-height: 28px;
        width: 100%;
        margin-top: 25px;
    }

    .link {
        text-align: center;
        color: $PrimaryColor;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        margin-top: 18px;
        cursor: pointer;
    }

    .verification-footer {
        text-align: center;
        padding: 16px 24px;
        font-size: 12px;
        line-height: 20px;

        a {
            color: $PrimaryColor;
            cursor: pointer;
        }
    }

    .error {
        @include errorMsg;
        text-align: center;
    }
}