@import "vars.scss";


.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $TopBarHeight;
    z-index: $HeaderZ;
    background-color: $TopBarBackground;
    color: $TopBarText;
    white-space: nowrap;

    @media ($IsDesktop) {
        padding-left: 45px;
    }

    .mobile-menu-button {
        display: inline-block;
        width: 74px;
        font-size: 30px;
        text-align: center;
        line-height: $TopBarHeight;
        vertical-align: top;

        @media ($IsDesktop) {
            display: none;
        }
    }

    .client-logo {
        display: inline-block;

        div {
            display: flex;
            align-items: center;
            height: $TopBarHeight;
        }

        img {
            max-height: $TopBarHeight;
            max-width: calc(100vw - 192px);
        }
    }

    .client-name {
        display: inline-block;
        width: calc(100vw - 192px);
        line-height: $TopBarHeight;
        overflow: hidden;
    }

    .right-buttons {
        position: fixed;
        right: 0;
        top: 0;
    }

    .help,
    .settings-cog {
        display: inline-block;
        width: 44px;
        line-height: $TopBarHeight;
        font-weight: 900;
        font-size: 16px;
        text-align: center;
        vertical-align: top;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        .popover {
            text-align: left;
            right: -4px;
            top: -14px;
            padding: 7px 0;

            .menu-item {
                padding: 10px 24px 10px 16px;
            }
        }
    }

    .user-icon {
        display: inline-block;
        width: 72px;
        text-align: center;
        line-height: $TopBarHeight;
        vertical-align: top;

        > span {
            display: inline-block;
            background: $TopBarUserIconBackground;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            color: $TopBarUserIconText;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
        }

        .popover {
            text-align: left;
            right: 7px;
            top: -2px;
            padding: 7px 0;

            .menu-item {
                padding: 10px 24px 10px 16px;
            }
        }
    }
}