@import "../vars.scss";



.gui-tooltip {
    background: var(--color-dark-background);
    color: $TextColorInverted;
    padding: 10px;
    bottom: 10px !important;
    border-radius: 5px;
    z-index: 10000;

    &[x-placement="top"] .arrow {
        border-style: solid;
        border-color: transparent;
        border-top-color: var(--color-dark-background);
        border-width: 5px 5px 0 5px;
        left: 50% !important;
        bottom: 0;
        transform: translate(-50%, 100%) !important;
    }
}