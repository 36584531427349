@import "vars.scss";



.tag {
    padding: 3px 12px;
    border-radius: 6px;
    font-size: 11px;
    font-weight: 600;
    color: $TextColor;
    background: $TagBackground;
}

.tag i.fa-times {
    margin-left: 4px;
    padding: 4px;
    cursor: pointer;
}