@import "../vars.scss";


.gui-textbox {
    position: relative;

    > input {
        @include guiInput;
        resize: none;

        &:focus {
            @include guiInputFocused;
        }
    }

    > label {
        @include guiLabel;
    }

    &.in-focus {
        > label {
            @include guiLabelFocused;
        }
    }

    &.has-value, &.in-focus {
        > label {
            @include guiLabelWithValue;
        }
    }

    &.disabled {
        opacity: .5;
    }

    &.has-error {
        > label {
            color: $Error;
        }

        input {
            border-color: $Error;
        }

        .error-msg {
            display: block;
            color: $Error;
            font-size: .8em;
            margin: .1em 0 0 .1em;
        }
    }
}
    
