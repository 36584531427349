@import "vars.scss";



.columns-cog {
    margin: 0 -6px 0 14px;
    padding: 4px 8px;
}

.column-picker {
    &.popover-anchor {
        display: inline-block;
    }

}