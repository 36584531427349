@import "vars.scss";

.alert-details {
    padding-bottom: 40px;

    @media ($IsMobile) {
        background: $ContentBackground;
    }

    .page-title {
        position: relative;

        @media ($IsMobile) {
            box-shadow: none;
            border-bottom: 1px solid $LightBorder;
        }

        i.fa-trash-alt {
            display: block;
            position: absolute;
            top: 0;
            right: 16px;
            width: 40px;
            line-height: 50px;
            color: $Warning;
            font-size: 16px;
            cursor: pointer;
        }
    }


    .container-card {
        padding: 30px 14px;

        @media ($IsDesktop) {
            margin: 24px;
        }

        @media ($IsMobile) {
            box-shadow: none;
        }
    }

    label {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
    }

    .fa-clock {
        color: var(--color-scheduled);
    }

    .fa-paper-plane {
        color: var(--color-sent);
    }

    .fa-edit {
        color: var(--color-draft-dot);
    }

    .field-container {
        padding-bottom: 20px;

        &.date {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }

        i {
            margin-right: 8px;
        }
    }

    .recipients-data {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }


    .message-container {
        background-color: $ContentBackground;
        padding: 0 11px;
    }

    .email-preview {
        margin-top: 16px;
        min-height: 200px;
        border-radius: 10px;
        background: $ContentBackground;
        box-shadow: $BoxShadow;
        overflow: hidden;

        .email-subject {
            padding: 15px 0 14px 11px;
            background: var(--color-email-subject-background);
            font-size: 14px;
            line-height: 22px;

            span {
                font-weight: 600;
                margin-right: 5px;
            }
        }
    }

    .attachments {
        margin-top: 22px;
        overflow: hidden;

        > div {
            background-color: var(--color-attachment-background);
            border-radius: 6px;
            padding: 2px 12px 2px 8px;
            font-size: 11px;
            float: left;
            clear: left;
            margin-bottom: 4px;

            @media ($IsDesktop) {
                float: none;
                display: inline-block;
                margin-right: 4px;
            }
        }

        i {
            color: var(--color-gray-border);
            margin-right: 8px;
        }
    }

    .voice-preview {
        margin-top: 5px;
    }

    .btn-container {
        overflow: hidden;
        text-align: right;
        padding: 0 14px;

        button {
            width: 156px;

            &:first-child {
                float: left;
            }
        }

        @media ($IsDesktop) {
            padding: 0 24px;

            button {
                margin-right: 16px;

                &:first-child {
                    margin-left: 16px;
                }
            }
        }

        &.mobile-three {
            .edit {
                width: 100%;
                margin-bottom: 16px;
            }

            .delete {
                float: left;
                width: calc(50% - 10px);
            }

            .close {
                width: calc(50% - 10px);
            }
        }
    }

    .loading {
        position: absolute;
        top: 116px;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 37px;
        background: var(--color-whiteout);
        text-align: center;
        font-size: 40px;
        color: $PrimaryColor;
        @include fadeIn;

        @media($IsDesktop) {
            top: 184px;
            padding-top: 37px;
        }
    }
}


