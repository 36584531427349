

:root {
    // Accent Colors
    --top-bar-background: 110, 168, 129;
    --primary-color: 29, 99, 198;
    --primary-hilight: 241, 246, 252;
    --primary-muted: 230, 239, 254;
    --warning: 197, 68, 67;
    --dim-active: 210, 224, 243;
    // Text Colors
    --text-primary: 54, 54, 54;
    --text-color-inverted: 255, 255, 255;
    --text-dim-primary: 166, 166, 166;
    --text-dim-hover: 237, 237, 237;
    --text-secondary: 107, 107, 107;
    --icon-gray: 117, 117, 117;
    // Backgrounds
    --page-background: 243, 243, 247;
    --content-background: 255, 255, 255;
    --sidebar-background: 41, 44, 45;
    --sidebar-subitem-background: 49, 52, 53;
    --stroke-low: 237, 237, 237;
    --secondary-button-hover: 239, 241, 246;
    // Borders
    --border-color: 181, 181, 181;
    --border-color-2: 166, 166, 166;
    --border-color-3: 210, 210, 210;
    --border-color-4: 236, 236, 238;
    --border-color-5: 225, 225, 225;
    // Font
    --font-family: 'Red Hat Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-size-large: 20px;
    --font-size-normal: 16px;
    --font-size-small: 14px;
    --font-size-tiny: 12px;
    // Rounded Corners
    --small-rounded-corners: 4px;
    --large-rounded-corners: 8px;
    --x-large-rounded-corners: 10px;
    // Other
    --box-shaodw-color: 0, 0, 0;
}





// MB Design System Colors
:root {
    --mb-color-styles-button-black: #616B7D;
    --inverted-text-color: #FFFFFF;
}



@import "vars.scss";

// GUI Colors
:root {
    // Text colors
    --color-text-darker: #{$DarkerTextColor};
    --color-text-lighter: #{$GuiButtonBlack};
    --color-text-lighter-opacity-70: #{$GuiButtonBlackOpacity70};
    --color-search-icon-text: #{$SearchIconTextColor};
    // Backgrounds
    --color-gray-medium-background: #{$GuiGrayMedium};
    --color-voice-tabs-background: #{$VoiceTabsBackground};
    --color-email-subject-background: #{$EmailSubjectBGColor};
    --color-help-background: #{$HelpBGColor};
    --color-blackout: #{$BlackoutColor};
    --color-ligther-blackout: #{$LighterBlackoutColor};
    --color-whiteout: #{$WhiteoutColor};
    --color-mobile-toolbar-background: #{$ToolbarMobileBackground};
    --color-dark-background: #{$GuiBlack};
    // Borders
    --color-gray-border: #{$GuiButtonBlackOpacity70};
    --color-lighter-gray-border: #{$LighterGrayBorderColor};
    // Primary
    --color-primary-hover: #{$GuiPrimaryHover};
    --color-primary-actve: #{$GuiPrimaryActive};
    --color-primary-opacity-70: #{rgba($GuiPrimary, .7)};
    --color-primary-opacity-20: #{rgba($GuiPrimary, .2)};
    --color-primary-opacity-05: #{rgba($GuiPrimary, .05)};
    // Selected
    --color-selected-item-background: #{$GuiSelection};
    --color-sidebar-hover: #{$SidebarHover};
    --color-sidebar-selected-item-background: #{$SidebarItemSelected};
    --color-sidebar-sub-item-hover-background: #{$SidebarSubItemHoverBackground};
    --color-secondary-button-active: #{$GuiSecondaryButtonActive};
    // Success
    --color-success: #{$GuiGreenDark};
    --color-success-background: #{$GuiGreenMutedShade};
    // Failure
    --color-warning-hover: #{$GuiRedHover};
    --color-warning-active: #{$GuiRedActive};
    --color-warning-background: #{$GuiRedMuted};
    // Icons
    --color-user-icon-background: #{$GuiGrayMedium};
    --color-attachment-background: #{$AttachmentBackground};
    --color-emergency: #{$GuiRed};
    --color-emergency-background: #{$EmergencyBackground};
    --color-sent: #{$GuiGreenHover};
    --color-sent-dot: #{$GuiGreen};
    --color-scheduled: #{$GuiGold};
    --color-scheduled-dot: #{$GuiGoldHover};
    --color-draft-dot: #{$GuiBlack40};
}