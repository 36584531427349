@import "vars.scss";



.popover-anchor {
    position: relative;
    z-index: $ModalZ;

    .popover {
        position: absolute;
        top: 10px;
        right: -20px;
        background: $ContentBackground;
        border-radius: 4px;
        padding: 14px 16px;
        box-shadow: $ModalBoxShadow;

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -16px;
            right: 20px;
            border: 8px solid transparent;
            border-bottom-color: $ContentBackground;
        }

        .menu-item {
            color: $TextColor;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;
            min-width: 94px;
        }
    }

    &.no-arrow {
        .popover::after {
            display: none;
        }
    }

    &.anchor-right {
        vertical-align: bottom;

        .popover {
            top: 1px;
            right: 0;
            left: auto;
        }
    }

    &.drop-up {
        .popover {
            top: unset;
            bottom: 0;
            right: unset;
            left: 0;
        }
    }

    &.drop-up-right {
        .popover {
            top: unset;
            bottom: 0;
            right: 0;
            left: unset;

            &::after {
                top: unset;
                bottom: -16px;
                right: 20px;
                border: 8px solid transparent;
                border-top-color: $ContentBackground;
                border-bottom-color: transparent;
            }
        }
    }

    &.popover-menu {
        .popover {
            padding: 8px 0;

            .menu-item {
                line-height: 40px;
                padding: 0 16px;
                white-space: nowrap;

                &:hover {
                    background: $PrimaryHilight;
                }
            }
        }
    }
}