@import "vars.scss";



.resizer {
    transition: height $AnimationLength ease;

    &.resizer-collapsed {
        height: 0;

        .resizer-content {
            display: none;
        }
    }
}