@import "vars.scss";


/* Group Properties Model */
.group-properties-modal {

}


/* Group Properties */
.group-properties .form-row {
    margin-bottom: 22px;
}

.group-properties .gui-textbox input {
    width: 100%;
}

.group-properties .group-type-row .gui-select {
    display: inline-block;
    margin-right: 22px;
    width: 279px;
}

.group-properties .group-type-row .gui-checkbox {
    display: inline-block;
}

.group-properties .caller-id-row .gui-textbox {
    display: inline-block;
    margin-right: 22px;
    width: 237px;
}

/* Logo */
.group-properties .add-logo label,
.group-properties .logo label {
    display: block;
    margin: 12px 0;
}

.group-properties .add-logo button {
    margin: 0 14px 0 0;
}

.group-properties .logo img {
    margin: 0 14px 0 0;
    border: 1px solid $OldGrayMedium;
    border-radius: $XLargeRoundedCorners;
    width: 300px;
    height: 80px;
    object-fit: contain;
}

.group-properties .logo span {
    line-height: 80px;
    vertical-align: bottom;
}