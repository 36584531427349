@import "vars.scss";


.confirm-delete-modal {
    .modal {
        width: 613px;
        max-width: 90%;
        text-align: center;
        color: $TextColor;
        padding: 50px 10px 45px 10px;
    }

    .modal-content {
        padding: 0;
    }

    h4 {
        margin: 0 0 14px 0;
        font-weight: 700;
        font-size: 20px;
    }

    h6 {
        margin-top: 0;
        font-weight: 400;
        font-size: 14px;
    }

    .btn-container {
        margin-top: 50px;
    }

    button {
        width: 174px;

        @media ($IsMobile) {
            width: 130px;
        }
    }

    .warning {
        margin-left: 18px;
    }
}