@import "vars.scss";


.send-alert {
    color: $TextColor;
    font-size: 14px;

    h2 {
        @include contentTitle;
        font-weight: 700;
        padding: 16px $ContentLeftPadding;
        box-shadow: $BoxShadow;
        text-align: center;

        @media ($IsDesktop) {
            text-align: left;
        }

        i {
            display: inline-block;
            float: right;
            cursor: pointer;
            line-height: 27px;
            color: var(--color-text-lighter);
        }
    }

    .work-area {
        box-shadow: $BoxShadow;

        @media ($IsMobile) {
            margin: 1px 0 30px 0;
        }

        @media ($IsDesktop) {
            margin: 16px $ContentLeftPadding;
            border-radius: 4px;
            overflow: hidden;
        }

        .preview {
            border-bottom: 0;
        }
    }
    //
    // Alert Type
    .alert-type {
        @media ($IsMobile) {
            text-align: center
        }

        i {
            color: var(--color-text-lighter-opacity-70);
            font-size: 20px;
            display: inline-block;
            width: 50px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border: 1px solid var(--color-text-lighter-opacity-70);
            border-radius: 25px;

            @media ($IsDesktop) {
                margin-right: 10px;
            }
        }

        span {
            display: inline-block;
            width: 180px;
            cursor: pointer;

            @media ($IsMobile) {
                width: 100px;
            }
        }

        .selected i {
            color: $TextColorInverted;
            background: $PrimaryColor;
            font-weight: 900;
        }

        .alert-type-text {
            display: inline;

            @media ($IsMobile) {
                padding-top: 6px;
                display: block;
            }
        }

        &.emergency .selected i {
            background: var(--color-emergency);
            border-color: var(--color-emergency);
        }
    }

    .next-button-container {
        text-align: right;
        margin-top: 50px;

        @media ($IsMobile) {
            .btn {
                margin-right: -13px;
                width: 155.62px;
                height: 32px;
            }
        }
    }
    //
    // Recipients
    .all-groups {
        display: block;
        margin: 5px 0 18px 1px;
    }

    .recipient-list {
        border: 1px solid var(--color-gray-medium-background);
        border-radius: 5px;
        padding: 10px 10px 16px 10px;
        margin-bottom: 20px;

        label {
            font-weight: 600;
            margin-right: 16px;
        }

        span {
            background: var(--color-gray-medium-background);
            padding: 3px 7px;
            border-radius: 5px;
            margin: 0 8px 11px 0;
            font-size: 12px;
            font-weight: 600;
            display: inline-block;

            i {
                margin-left: 8px;
                font-weight: 400;
                cursor: pointer;
            }
        }

        .btn {
            margin: -2px 0 0 16px;
            padding: 5px 10px;
            font-size: 13px;
            font-weight: 600;
            background: $ContentBackground;

            i {
                font-weight: 400;
                margin-right: 7px;
            }
        }
    }
    //
    // Message
    .subject {
        margin-bottom: 30px;

        input {
            width: 100%;

            @media ($IsDesktop) {
                width: 400px;
            }
        }
    }

    .gui-textarea {
        textarea {
            height: 183px;
            width: 100%;
            padding: 16px;
            margin-bottom: 35px;
        }
    }

    .choose-template {
        position: absolute;
        width: 30px;
        height: 30px;
        margin: -36px 0 0 10px;
        border: 0;
        background: url('../../public/lib/images/template-button.svg') center center no-repeat;
        cursor: pointer;
    }

    .voice-recorder {
        max-width: 400px;
        margin: 5px 0;

        @media ($IsMobile) {
            margin: auto;
            width: 72%;
        }

        @media (max-width: 450px) {
            margin: auto;
            width: 100%;
        }

        .react-audio-player {
        }
    }

    .html-editor {
        margin-bottom: 20px;

        .k-iframe {
            height: 200px !important;
        }
    }

    .voice-text-toggle {
        display: block;
        box-shadow: $BoxShadow;
        position: relative;
        background: var(--color-voice-tabs-background);
        opacity: 70%;
        max-width: 400px;
        border-radius: 4px;
        margin-top: 42px;
        margin-bottom: 24px;

        @media ($IsMobile) {
            left: 50%;
            transform: translate(-50%, 0%);
        }

        span {
            display: block;
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            padding: 10px 4px;
            cursor: pointer;

            &.active {
                padding: 10px 4px;
                background: $ContentBackground;
                border-radius: 4px;
            }
        }

        .voice-recording {
            padding: 3px;
            display: inline-block;
            width: 50%;
            text-align: center;
        }

        .text-to-voice {
            padding: 3px;
            display: inline-block;
            width: 50%;
            text-align: center;

            &.active {
                padding: 10px 4px;
                background: $ContentBackground;
                border-radius: 4px;
            }
        }
    }

    .text-to-speech {
        .react-audio-player {
            margin-top: 30px;

            @media ($IsMobile) {
                width: 90%;
                margin-left: 15px;
            }
        }
    }

    .update-tts {
        float: left;
        padding-left: 50px;
        padding-right: 50px;
    }

    .attachments {
        @media ($IsMobile) {
            display: inline-grid;
        }

        .attachment {
            display: inline-block;
            color: $PrimaryColor;
            font-size: 11px;
            line-height: 20px;
            background: var(--color-attachment-background);
            margin: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 6px;

            .icon {
                color: $TextColor;
                margin: 0 7px 0 6px;
                opacity: .7;
            }

            .close {
                color: var(--color-text-lighter);
                float: right;
                line-height: 20px;
                padding: 0 8px 0 15px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500
            }
        }
    }

    .error-msg {
        @include errorMsg;
        font-size: 13px;
        margin-bottom: 10px;
    }
    //
    // Preview
    .preview .resizer-content {
        > label {
            display: block;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 5px;
        }

        > div {
            margin-bottom: 25px;
        }

        .react-audio-player {
            margin-top: 8px;
        }
    }
    //
    // Bottom bar
    .bottom-button-bar {
        margin-bottom: 50px;
        padding: 5px 40px;

        @media ($IsDesktop) {
            padding: 0 40px;

            .schedule {
                float: right;
                margin-left: 20px;
            }
        }

        @media ($IsMobile) {
            padding: 0 15px 32px 15px;

            button {
                width: 40%;
                max-width: 156px;
                height: 32px;
                text-align: center;
            }

            .schedule {
                float: left;
            }
        }

        .send {
            float: right;
            margin-left: 20px;
        }

        .save-as {
            margin-left: 20px;
        }


        &.mobile-three {
            .send {
                width: 100%;
                max-width: none;
                margin-bottom: 16px;
            }

            .save-as {
                float: left;
                width: calc(50% - 10px);
                margin-left: 0;
            }

            .schedule {
                float: right;
                width: calc(50% - 10px);
            }
        }
    }
}