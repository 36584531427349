@import "../vars.scss";


.gui-radio {
    cursor: pointer;
    white-space: nowrap;
    position: relative;

    input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    i.dot {
        position: relative;
        display: inline-block;
        width: 22px; /* 1.4em */
        height: 22px; /* 1.4em */
        border-radius: 50%;
        background: $OutlineButtonBorder;
        vertical-align: middle;
        transition: background-color $AnimationLength;
    }

    i.dot::after {
        content: "";
        position: absolute;
        box-sizing: border-box;
        top: 2px; /* .15em; */
        left: 2px; /* .15em; */
        border: 2px solid $ContentBackground; /* .15em */
        width: 18px; /* 1.1em; */
        height: 18px; /* 1.1em; */
        border-radius: 50%;
        background-color: $ContentBackground;
        transition: background-color $AnimationLength, border-color $AnimationLength;
    }
    
    input:checked + i.dot {
        background-color: $PrimaryColor;
    }

    input:checked + i.dot::after {
        background-color: $PrimaryColor;
    }

    i.dot + span {
        margin-left: .6em;
    }

    &.disabled {
        opacity: .35;
        pointer-events: none;
    }
    /*.gui-radio .error-msg {
        display: none;
    }

    .gui-radio.has-error .error-msg {
        display: block;
        color: var(--color-error);
        font-size: .8em;
        margin: .1em 0 0 .1em;
    }*/
}
    
