@import "../vars.scss";


.gui-select {
    position: relative;

    > input {
        @include guiInput;

        &:focus {
            @include guiInputFocused;
        }
    }

    > label {
        @include guiLabel;
        top: .35em;
    }

    &.in-focus {
        > label {
            @include guiLabelFocused;
        }
    }

    &.has-value, &.in-focus {
        > label {
            @include guiLabelWithValue;
        }
    }

    &.has-error {
        > label {
            color: $Error;
        }

        input {
            border-color: $Error;
        }

        .error-msg {
            display: block;
            color: $Error;
            font-size: .8em;
            margin: .1em 0 0 .1em;
        }
    }
}


.gui-select {
    .search-select {

        .ss__control {
            padding: 1px 0;
            background-color: $InputBackgroundColor;
            border-color: $InputBorderColor;
            cursor: pointer;

            &.ss__control--is-focused {
                border: 1px solid $PrimaryColor;
                box-shadow: none;
            }
            /* Vertical spearator between selected value and chevron */
            .ss__indicator-separator {
                display: none;
            }
            /*.ss__value-container {
                color: $FormFormcontrolColor;
            }*/
            /*&.ss__control--is-disabled {
                background-color: $FormControlDisabledBackground;
            }*/
        }

        .ss__menu {
            z-index: 9999;
            /** {
                color: $Base3;
            }*/
            /*.ss__option--is-selected {
                background-color: $FormBase4;
                color: $FormBase1;
            }

            .ss__option--is-focused {
                background-color: lighten($FormBase4, 20%);
                color: $FormBase1;
                box-shadow: none;
            }*/
        }
        /*
        .ss__option--is-disabled {
            color: lighten($Base2, 40%);
        }*/

        &.clear-visible {
            .ss__dropdown-indicator {
                display: none;
            }
        }
    }
}

body > [class^="css-"] {
    z-index: 9999;

    .ss__menu {
        margin-top: 0;
        border-radius: 4px;

        .ss__option {
            cursor: pointer;
        }
    }
}