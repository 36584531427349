@import "../vars.scss";


.gui-time {
    position: relative;

    input.rc-time-picker-input {
        @include guiInput;

        &:focus {
            @include guiInputFocused;
        }
    }

    > label {
        @include guiLabel;
    }

    &.in-focus {
        > label {
            @include guiLabelFocused;
        }
    }

    &.has-value, &.in-focus {
        > label {
            @include guiLabelWithValue;
        }
    }

    &.has-error {
        > label {
            color: $Error;
        }

        input {
            border-color: $Error;
        }
    }
}

.gui-time {
    .rc-time-picker {
        width: 100%;
    }

    .rc-time-picker-clear {
        top: 8px;

        .rc-time-picker-clear-icon:after {
            font-size: 20px;
        }
    }
}


.rc-time-picker-panel {
    .rc-time-picker-panel-inner {
        margin-top: 43px;
        border-radius: 4px;
        width: 260px;
    }

    .rc-time-picker-panel-input-wrap {
        display: none;
    }

    .rc-time-picker-panel-select {
        font-size: 16px;
        width: 33%;

        &:first-child {
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-bottom-right-radius: 4px;
        }
    }

    &.rc-time-picker-panel-column-2 .rc-time-picker-panel-select {
        width: 50%;
    }
}
    
