@import "vars.scss";


.pager {
    font-size: $FontSizeTiny;
    border-radius: 0 0 $LargeRoundedCorners $LargeRoundedCorners;

    span {
        display: inline-block;
        cursor: pointer;
        min-width: 30px;
        text-align: center;
        line-height: 30px;
    }

    .active {
        color: $TextColorInverted;
        background: $PrimaryColor;
        border-radius: $SmallRoundedCorners;
    }

    .page-size {
        display: inline-block;
        margin-left: 30px;
        cursor: pointer;

        label {
            margin-right: 4px;
        }

        i {
            margin-left: 2px;
        }
    }

    .showing-text {
        display: block;
        float: right;
        color: var(--color-text-darker);
        line-height: 50px;
        margin-right: 16px;
        opacity: .5;
    }

    .popover {
        padding: 0;
        overflow: hidden;

        .menu-item {
            padding: 7px 20px;

            &:hover {
                background-color: var(--color-primary-opacity-05);
            }
        }
    }
}
    
