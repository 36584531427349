@import "vars.scss";


.successful-page {

    .container-card {
        background: var(--color-email-subject-background);
        padding-bottom: 50px;
    }

    .body-container {
        text-align: center;

        img {
            width: 350px;
            max-width: 90%;
            height: auto;
            padding-top: 110px;
            padding-bottom: 50px;
        }
    }

    .btn-container {
        text-align: center;
        margin-bottom: 170px;

        .gui-btn {
            width: 240px;
            height: 45px;
        }
    }
}
